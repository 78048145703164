
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">
                <v-layout row wrap px-0 mb-4>
                    <v-flex xs9>
                        <v-breadcrumbs class="px-0" :items="$store.getters.language.data.display_text.edit_path">
                            <template v-slot:item="{ item }">
                                <v-breadcrumbs-item :to="item.path" :disabled="item.disabled">
                                    {{ item.title.toUpperCase() }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-flex>
                    <v-flex xs3>
                    </v-flex>
                </v-layout>
            <form @submit.prevent="updateDisplayText" autocomplete="off">
                <v-layout row wrap>
        
                    <v-flex xs12>
                        <v-text-field v-model="display_text.display_text_key" type="text" :label="$store.getters.language.data.display_text.display_text_key" 
                            class="mx-1" disabled filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg4 xl4 md4 sm4>
                        <v-textarea rows="1" auto-grow v-model="display_text.display_text_en" type="textarea" :label="$store.getters.language.data.display_text.display_text_en" 
                            class="mx-1"  filled outlined dense required >
                        </v-textarea>
                    </v-flex>
                    <v-flex xs12 lg4 xl4 md4 sm4>
                        <v-textarea rows="1" auto-grow v-model="display_text.display_text_ar" type="textarea" :label="$store.getters.language.data.display_text.display_text_ar" 
                            class="mx-1"  filled outlined dense required >
                        </v-textarea>
                    </v-flex>
                    <v-flex xs12 lg4 xl4 md4 sm4>
                        <v-textarea rows="1" auto-grow v-model="display_text.display_text_ku" type="textarea" :label="$store.getters.language.data.display_text.display_text_ku" 
                            class="mx-1"  filled outlined dense required >
                        </v-textarea>
                    </v-flex>
                    <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="display_text.display_text_info" type="textarea" :label="$store.getters.language.data.display_text.display_text_info" 
                            class="mx-1"  filled outlined dense  >
                        </v-text-field>
                    </v-flex> -->
                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn  color="success"    type="submit">{{$store.getters.language.data.display_text.update_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
        </v-container>
    <v-snackbar v-model="snackbar.value" :color="snackbar.color">
        {{snackbar.text}}
    </v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/display_text.request.js'
    export default {
        data() {
            return {
                display_text: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                
            }
        },
        computed: {
           
            page(){
                const pageKey = 'DISPLAYTEXT'
                return {}
            }
        },
        mounted(){
            this.id = this.$route.params.id
            this.getOneDisplayText()
        },
        methods: {
            getOneDisplayText() {
                this.loading = true
                requests.getOneDisplayText(this.id).then(r =>{
                    if (r.status == 200) {
                        this.display_text = r.data.display_text
                        
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read DisplayText',
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read DisplayText',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            updateDisplayText() {
                this.loading = true
                requests.updateDisplayText(this.id,this.display_text).then(r => {
                    if (r.status == 200) {
                        this.snackbar = {
                            value: true,
                            text: 'DisplayText Updated',
                            color: 'success'
                        }
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Update Faild',
                            color: 'error'
                        }
                        this.loading = false
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Update Faild',
                        color: 'error'
                    }
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
            },
        },
    }
</script>
                    